import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import SEO from '../components/Seo'
import Layout from '../components/Layout'
import ContentContainer from '../components/ContentContainer'
import ProjectInfo from '../components/ProjectInfo'
import VimeoPlayer from '../components/VimeoPlayer'
import TextButton from '../components/TextButton'
import { spacing, breakpoints } from '../utils/constants'
import VideoShowcase from '../components/VideoShowcase'

const DownloadContainer = styled.div`
  margin-top: ${spacing.md};

  @media (max-width: ${breakpoints.mobile}) {
    margin-top: ${spacing.sm};
  }
`

const RicardoMilla = ({ data }) => {
  const project = data.markdownRemark.frontmatter

  return (
    <Layout hideFooter>
      <SEO title={`${project.artist} | ${project.projectTitle}`} />
      <ContentContainer centered>
        <VideoShowcase style={{ minHeight: '100rvh' }}>
          <VimeoPlayer Src="https://player.vimeo.com/video/397582425?background=1&autopause=0" />
          <DownloadContainer>
            <TextButton
              href="https://drive.google.com/file/d/1fK6mUm6O3QqDpmOEkTVbRQzlE1d-iUAt/view?usp=sharing"
              largeButton
            >
              Download Soaps
            </TextButton>
          </DownloadContainer>
        </VideoShowcase>
      </ContentContainer>
      <ProjectInfo
        projectTitle={project.projectTitle}
        projectDescription={project.projectDescription}
        artist={project.artist}
        artistHeadshot={project.artistHeadshot.childImageSharp.fluid}
        artistDescription={project.artistDescription}
        website={project.website}
        instagram={project.instagram}
        twitter={project.twitter}
        email={project.email}
      />
    </Layout>
  )
}

export default RicardoMilla

export const RicardoMillaQuery = graphql`
  query RicardoMillaQuery {
    markdownRemark(fields: { slug: { eq: "/ricardo-milla/" } }) {
      id
      frontmatter {
        artist
        artistHeadshot {
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        artistDescription
        projectTitle
        projectDescription
        website
        instagram
        twitter
        email
      }
    }
  }
`
